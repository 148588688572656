import { forwardRef, useState, useEffect, useMemo, useRef, BaseSyntheticEvent } from 'react';
import placeholder from '../../images/placeholders/banner-white.png';
import PinTypeRadioButtons from '../pin_types/PinTypeRadioButtons';
import PinStatusRadioButtons from './PinStatusRadioButtons';
import PinDroppersButton from './PinDroppersButton';
import Loader from '../utility/Loader';
import PinViewModel from '../../models/PinViewModel';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import usePins from './usePins';
import PinTypeFilterableFields from '../pin_types/PinTypeFilterableFields';
import Tags from '../shared/Tags';
import useFeatures from '../../hooks/useFeatures';
import { useParams } from 'react-router-dom';
import useWorkspaceContext from '../../hooks/useWorkspaceContext';
import PinCategory from './PinCategory';
import PinLastUpdatedAt from './PinLastUpdatedAt';

const MarginBottomTypes: {
  [key: number]: string
} = {
  0: 'mb-0',
  1: 'mb-1',
  2: 'mb-2',
  3: 'mb-3',
  4: 'mb-4',
  5: 'mb-5',
  6: 'mb-6',
}

const OverflowTypes: {
  [key: string]: string
} = {
  'auto': 'overflow-auto',
  'hidden': 'overflow-hidden',
  'visible': 'overflow-visible',
  'scroll': 'overflow-scroll',
  'x-auto': 'overflow-x-auto',
  'x-hidden': 'overflow-x-hidden',
  'x-visible': 'overflow-x-visible',
  'x-scroll': 'overflow-x-scroll',
  'y-auto': 'overflow-y-auto',
  'y-hidden': 'overflow-y-hidden',
  'y-visible': 'overflow-y-visible',
  'y-scroll': 'overflow-y-scroll',
}

const ReportResourceContainer = ({ children, mb = 0, heightFull, overflow }: { children: any, mb?: number, heightFull?: boolean, overflow?: string }) => <div className={`rounded-md relative flex flex-col border shadow-sm bg-white ${overflow ? OverflowTypes[overflow] : ''} ${heightFull ? 'h-full' : ''} ${MarginBottomTypes[mb]}`}>
  {children}
</div>

const ReportResourceHeader = ({ children, mb = 4, sticky }: { children: any, mb?: number, sticky?: boolean }) => <div className={`${sticky ? 'sticky z-10 top-0' : ''} rounded-t-md px-4 py-2 bg-white font-semibold flex items-center justify-between border-b shadow-sm ${MarginBottomTypes[mb]}`}>
  {children}
</div>

export {
  ReportResourceContainer,
  ReportResourceHeader,
  OverflowTypes
}

const PinsSection = ({
  loaded,
  excludedPins,
  setExcludedPins,
  pins,
  setSelectedPin,
  shown,
}: {
  loaded: boolean;
  excludedPins: any[];
  setExcludedPins: any;
  pins: any[];
  setSelectedPin: any;
  shown: boolean;
}) => (
  <div
    className={`bg-white border rounded-md ${!shown && 'hidden'
      }`}
  >
    <div className="h-full overflow-y-auto p-3">
      {pins.length === 0 && loaded && (
        <p className="empty-data-txt text-center">
          No pins found with current filters
        </p>
      )}
      {pins.map((obj: any) => {
        let pin = new PinViewModel(obj) as any;
        let excluded = excludedPins.find(
          (p: any) => p.objectId === pin.objectId
        );
        return (
          <div
            key={`pin_filter_card_pin_${pin.objectId}`}
            className="bg-white shadow flex rounded-sm border border-gray-200 mb-2 p-1 relative cursor-pointer"
          >
            <div
              className="flex flex-grow items-center hover:opacity-70"
              onClick={() => setSelectedPin(pin)}
            >
              <img
                src={pin.latestPhoto() ? pin.latestPhoto().medium : placeholder}
                className="bg-gray-100 object-cover w-16 h-16"
                loading='lazy'
              />
              <div className="flex flex-col flex-grow px-2 mb-1">
                <div className="flex flex-row items-center mb-1">
                  <div className="font-semibold text-xs leading-tight mr-2">
                    {pin.identifier}
                  </div>

                  <PinLastUpdatedAt pin={pin} />
                </div>
                <div className="flex flex-row items-center flex-wrap mt-1">
                  <div className="bg-blue-100 rounded-sm text-xxs px-1 mr-2">
                    {pin.pin_type && pin.pin_type.name}
                  </div>
                  <div
                    className="bg-gray-200 text-white rounded-sm text-xxs px-1 capitalize mr-2"
                    style={{ backgroundColor: pin && pin.getStatusColor() }}
                  >
                    {pin.getStatus()}
                  </div>
                  {pin
                    .categoryFields()
                    .map(
                      (
                        {
                          field_option: { color, name },
                        }: { field_option: { color: string; name: string } },
                        index: boolean
                      ) => {
                        return (
                          <PinCategory
                            key={`report_pin_${pin.objectId}_category_${index}`}
                            size="sm"
                            name={name}
                            color={color}
                          />
                        );
                      }
                    )}
                  {pin.private && (
                    <div className="bg-gray-500 text-white rounded-sm text-xxs px-1 mt-1 capitalize">
                      Private
                    </div>
                  )}
                </div>

                {pin.latestNote() && (
                  <div className="text-xxs mt-1 py-0.5 px-1 bg-gray-100 rounded truncate max-w-sm">
                    {pin.latestNote()}
                  </div>
                )}
              </div>
            </div>
            <div
              className="flex flex-shrink items-center px-5 hover:opacity-70 border-l border-gray-100"
              onClick={() =>
                excluded
                  ? setExcludedPins([
                    ...excludedPins.filter(
                      (p: any) => p.objectId !== pin.objectId
                    ),
                  ])
                  : setExcludedPins([...excludedPins, obj])
              }
            >
              <button
                className={`focus:outline-none h-3 w-3 rounded-full border border-secondary ${excluded ? 'bg-white' : 'bg-secondary'
                  }`}
              ></button>
            </div>
            <a className="hidden cursor-pointer absolute -top-1 -right-1 text-xs font-bold flex items-center justify-center h-6 w-6 rounded-full text-white text-center bg-secondary">
              x
            </a>
          </div>
        );
      })}
    </div>
  </div>
);

function PinFilterCard({
  reportType,
  previousInspectionDate,
  viewOptions: {
    droppedBy = true,
    header = true,
    previousNeedsAction = true,
    privatePins = true,
  } = {},
}: any) {
  const {
    pins,
    pinsPagy,
    loadingPins,
    setSelectedPin,
    excludedPins,
    setExcludedPins,
    pinFilter,
    setPinFilter,
    loadingPinTypes,
    pinTypes,
  } = usePins();

  const {
    where: {
      days: passedDays = null,
      status = [],
      pin_type_id = [],
      field_values = [],
      private: includePrivate = [false],
      notes: { notetaker_id = [] } = {},
      tags: rawTags = [],
    } = {},
    or_where = {},
  } = pinFilter;

  const { features: { rollout: { pin_tags = false } = {} } = {} } =
    useFeatures();
  const { workspace } = useWorkspaceContext();
  const [fieldValues, setFieldValues] = useState(field_values?.value || []);
  const [creatorIds, setCreatorIds] = useState([...notetaker_id]);
  const [chosenPinTypes, setChosenPinTypes] = useState([...pin_type_id]);
  const [chosenPinStatuses, setChosenPinStatuses] = useState(status);
  const [showPins, setShowPins] = useState(false);
  const [datePickerType, setDatePickerType] = useState('days');
  const [days, setDays] = useState(passedDays);
  const [tags, setTags] = useState<{ name: string }[]>(
    workspace.tags_with_colors?.filter?.((tag: any) =>
      rawTags.includes(tag.name)
    )
  );
  const { project_id } = useParams<{ project_id: string }>();

  // TODO: this should probably not allow undefined values.
  const [startDate, setStartDate] = useState<Date | null>(
    previousInspectionDate ? new Date(previousInspectionDate) : new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [includeAllNeedsAction, setIncludeAllNeedsAction] = useState(true);
  const dateDebounce = useRef<NodeJS.Timeout | null>(null);
  const setDebouncedDate = (value: any) => {
    if (dateDebounce.current) clearTimeout(dateDebounce.current);
    dateDebounce.current = setTimeout(() => {
      setPinFilter({
        ...pinFilter,
        where: { ...pinFilter.where, days: value },
      })
    }, 500);
  }

  const onRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end)
      setPinFilter({
        ...pinFilter,
        where: {
          ...pinFilter.where,
          start_date: start,
          end_date: end,
          days: null,
        },
      });
  };

  useEffect(() => {
    if (!workspace?.objectId || !!tags.length || !rawTags.length) return;
    setTags(
      workspace.tags_with_colors?.filter?.((tag: any) =>
        rawTags.includes(tag.name)
      )
    );
  }, [workspace?.objectId, rawTags, tags]);
  // TODO: these effects should probably not be using JSON.stringify.
  // JSON.stringify is nondeterministic for nontrivial types.
  // Maybe lodash deepequals?
  useEffect(() => {
    if (JSON.stringify(notetaker_id) === JSON.stringify(creatorIds)) return;
    setCreatorIds([...notetaker_id]);
  }, [notetaker_id]);

  useEffect(() => {
    if (JSON.stringify(chosenPinStatuses) === JSON.stringify(status)) return;
    setChosenPinStatuses([...status]);
  }, [status]);

  useEffect(() => {
    if (JSON.stringify(chosenPinTypes) === JSON.stringify(pin_type_id)) return;
    setChosenPinTypes(pin_type_id);
  }, [pin_type_id]);

  useEffect(() => {
    setIncludeAllNeedsAction(or_where?.status?.includes('needs_action'));
  }, [or_where]);

  useEffect(() => {
    if (datePickerType !== 'days' || days === passedDays) return;
    setDays(passedDays);
  }, [passedDays]);

  useEffect(() => {
    if (
      JSON.stringify(fieldValues?.value) === JSON.stringify(field_values?.value)
    )
      return;
    setFieldValues(field_values?.value);
  }, [field_values]);

  const pinDroppersButton = useMemo(
    () => (
      <PinDroppersButton
        onClick={undefined}
        chosenIds={creatorIds}
        onChosen={(chosenId: any, chosen: any) => {
          chosen
            ? setPinFilter((existing: any) => ({
              ...existing,
              where: {
                ...pinFilter.where,
                notes: {
                  notetaker_id: [
                    ...creatorIds.filter((id) => id !== chosenId),
                  ],
                },
              },
            }))
            : setPinFilter((existing: any) => ({
              ...existing,
              where: {
                ...pinFilter.where,
                notes: { notetaker_id: [...creatorIds, chosenId] },
              },
            }));
        }}
      />
    ),
    [creatorIds]
  );

  const CustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <input
      type="text"
      placeholder={'N/A'}
      value={value}
      onClick={onClick}
      onChange={() => { }}
      // @TODO: solve why Typescript compiler disallows the ref parameter to be
      // passed here. There is likely a good reason.
      // @ts-ignore
      ref={ref}
      className={
        'text-center py-1 px-2 relative text-xs w-34 border-gray-100 outline-none focus:outline-none focus:ring-0'
      }
    />
  ));

  return (
    <ReportResourceContainer heightFull overflow='y-auto'>
      {header && (
        <ReportResourceHeader sticky>
          <span>Pins</span>
          <button
            onClick={() => setShowPins(!showPins)}
            className={`focus:outline-none flex text-sm tracking-tight ml-3 rounded-md ${showPins ? 'bg-secondary text-white' : 'bg-white text-secondary'
              } px-3 py-1 border shadow-sm hover:opacity-70 font-medium cursor-pointer`}
          >
            {showPins ? 'Filter' : 'View'} Pins{' '}
            {loadingPins ? (
              <Loader color={'text-gray-700'} margin={'ml-2'} />
            ) : (
              <span className="font-semibold ml-1">({pinsPagy?.count || 'N/A'})</span>
            )}
          </button>
        </ReportResourceHeader>
      )}
      <div className="p-4">
        <div className={`text-sm ${showPins && 'hidden'}`}>
          <div className="items-center relative">
            {previousInspectionDate && (
              <div className="text-right text-xxs text-secondary italic -mt-2 absolute right-0">
                Last {reportType.name} Report on{' '}
                {moment(previousInspectionDate).format('MMM D, YYYY')}
              </div>
            )}
            {droppedBy && (
              <div className="mb-2 flex-col xs:flex-row flex">
                <div className="flex items-center mb-4 xs:mb-0">
                  <div className="mr-2">Dropped by</div>
                  {pinDroppersButton}
                </div>
                <div className="flex items-center">
                  <select
                    value={datePickerType}
                    className="ml-0 mr-2 xs:ml-2 border border-gray-100 rounded-lg text-xs shadow focus:ring-0"
                    onChange={({ target: { value } }) => setDatePickerType(value)}
                  >
                    <option key={'days'} value={'days'}>
                      in the last
                    </option>
                    <option key={'range'} value={'range'}>
                      between
                    </option>
                  </select>
                  <div className="ml-0 mr-2 xs:ml-2 hidden">in the last</div>
                  {datePickerType === 'days' ? (
                    <>
                      <div className="mr-2 border rounded-md overflow-hidden">
                        <input
                          type="number"
                          placeholder={'All'}
                          defaultValue={days || ''}
                          onInput={({ target: { value } }: BaseSyntheticEvent) => setDebouncedDate(value)}
                          className="text-center py-1 px-2 relative text-xs w-14 border-gray-100 outline-none focus:outline-none focus:ring-0"
                        />
                      </div>
                      <div>day(s)</div>
                    </>
                  ) : (
                    <DatePicker
                      selectsRange
                      selected={startDate}
                      onChange={onRangeChange}
                      startDate={startDate}
                      dateFormat={'MM/dd/yy'}
                      endDate={endDate}
                      customInput={<CustomInput />}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="mb-2">Type(s)</div>
          <PinTypeRadioButtons
            loading={loadingPinTypes}
            pinTypes={pinTypes}
            chosen={chosenPinTypes}
            onChosen={(chosenId: any, chosen: any) => {
              let newPinTypeIds = chosen
                ? chosenPinTypes.filter((id) => id !== chosenId)
                : [...chosenPinTypes, chosenId];
              let newFilter = {
                ...pinFilter,
                where: { ...pinFilter.where, pin_type_id: newPinTypeIds },
              };
              if (newFilter?.or_where?.pin_type_id)
                newFilter.or_where.pin_type_id = newPinTypeIds;
              setPinFilter(newFilter);
            }}
          />
          <div className="mt-6 mb-2">Status</div>
          <PinStatusRadioButtons
            loading={loadingPinTypes}
            pinTypes={pinTypes}
            chosen={chosenPinStatuses}
            onChosen={(pinStatus: any, chosen: any) => {
              if (pinStatus === null)
                return setPinFilter({
                  ...pinFilter,
                  where: { ...pinFilter.where, status: [] },
                });
              chosen
                ? setPinFilter({
                  ...pinFilter,
                  where: {
                    ...pinFilter.where,
                    status: [
                      ...chosenPinStatuses
                        .filter((value: any) => value !== pinStatus.value)
                        .map((value: any) => value),
                    ],
                  },
                })
                : setPinFilter({
                  ...pinFilter,
                  where: {
                    ...pinFilter.where,
                    status: [...chosenPinStatuses, pinStatus.value],
                  },
                });
            }}
          />
          {pin_tags && (
            <>
              <div className="mt-6 mb-2">Tags</div>
              <Tags
                tags={tags}
                taggableType="Pin"
                maxListHeight="max-h-40"
                keyPrefix={`${project_id}tagPinFilter`}
                containerClassName="normal-case font-normal font-inter items-center flex my-2 flex-wrap"
                tagClassName="mr-1 mb-1"
                canAdd
                allowCreate={false}
                onAdd={(newTag: { name: string }) => {
                  const newTags = [...tags.filter(({ name }) => name !== newTag.name), newTag];
                  setTags(newTags);
                  setPinFilter({
                    ...pinFilter,
                    where: {
                      ...pinFilter.where,
                      tags: newTags.map(({ name }) => name),
                    },
                  });
                }}
                onRemove={(oldTag: { name: string }) => {
                  const newTags = [
                    ...tags.filter(({ name }) => name !== oldTag.name),
                  ];
                  setTags(newTags);
                  setPinFilter({
                    ...pinFilter,
                    where: {
                      ...pinFilter.where,
                      tags: newTags.map(({ name }) => name),
                    },
                  });
                }}
                onClearTags={() => setTags([])}
                maxTags={0}
                onDestroy={undefined}
                onUpdate={undefined}
                triggerTestId={undefined}
              />
            </>
          )}
          {previousNeedsAction && (
            <>
              <div className="mt-6 mb-2">
                Include previous{' '}
                <span className="text-primary font-bold">Needs Action</span> pins?
              </div>
              <a
                data-testid="buttonIncludePreviousYes"
                className={`cursor-pointer mr-2 px-2 py-1 border rounded-md ${includeAllNeedsAction
                  ? 'bg-blue-50 text-secondary border-secondary shadow'
                  : 'bg-white'
                  } hover:shadow`}
                onClick={() =>
                  setPinFilter({
                    ...pinFilter,
                    or_where: {
                      pin_type_id: pinFilter.where.pin_type_id,
                      status: ['needs_action'],
                    },
                  })
                }
              >
                Yes
              </a>
              <a
                data-testid="buttonIncludePreviousNo"
                className={`cursor-pointer mr-2 px-2 py-1 border rounded-md ${!includeAllNeedsAction
                  ? 'bg-blue-50 text-secondary border-secondary shadow'
                  : 'bg-white'
                  } hover:shadow`}
                onClick={() => setPinFilter({ ...pinFilter, or_where: null })}
              >
                No
              </a>
            </>
          )}
          {privatePins && (
            <>
              <div className="mt-6 mb-2">Include private pins?</div>
              <div className="flex flex-wrap text-xs">
                <a
                  className={`cursor-pointer mr-2 px-2 py-1 border rounded-md ${includePrivate?.includes?.(true)
                    ? 'bg-blue-50 text-secondary border-secondary shadow'
                    : 'bg-white'
                    } hover:shadow`}
                  onClick={() => setPinFilter({
                    ...pinFilter,
                    where: {
                      ...pinFilter.where,
                      private: [true, false],
                    },
                  })}
                >
                  Yes
                </a>
                <a
                  className={`cursor-pointer mr-2 px-2 py-1 border rounded-md ${!includePrivate?.includes?.(true) || includePrivate === false
                    ? 'bg-blue-50 text-secondary border-secondary shadow'
                    : 'bg-white'
                    } hover:shadow`}
                  onClick={() => setPinFilter({
                    ...pinFilter,
                    where: {
                      ...pinFilter.where,
                      private: [false],
                    },
                  })}
                >
                  No
                </a>
              </div>
            </>
          )}
          <div className="mt-6 mb-2">Filters</div>
          <PinTypeFilterableFields
            loading={loadingPinTypes}
            pinTypes={pinTypes}
            chosenPinTypes={chosenPinTypes}
            fieldValues={fieldValues}
            onChosen={(value: any) => {
              setPinFilter({
                ...pinFilter,
                where: {
                  ...pinFilter.where,
                  field_values: { value },
                },
              });
            }}
          />
        </div>
        <PinsSection
          loaded={!loadingPins}
          excludedPins={excludedPins}
          setExcludedPins={setExcludedPins}
          pins={pins}
          setSelectedPin={setSelectedPin}
          shown={showPins}
        />
      </div>
      {header && (
        <div className="text-center text-xs italic sticky z-20 bottom-0 leading-tight mt-3 py-3 bg-white text-gray-600 border-t border-gray-100">
          {showPins
            ? 'Click pin to view. Toggle pin inclusion on right. Click "Filter Pins" above to go back to filter'
            : 'Click "View Pins" above to show a list of pins for editing'}
        </div>
      )}
    </ReportResourceContainer>
  );
}

export default PinFilterCard;
