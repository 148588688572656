import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import * as jszip from "jszip";
import { toText } from '../../../utility/toBase64';
const PDFJS = require('pdfjs-dist');
const toGeoJson = require('@tmcw/togeojson');

const IMAGE_EXTENSIONS = ['JPEG', 'JPG', 'PNG'];
const TIFF_FILE_TYPES = ['TIF', 'TIFF'];
const KML_FILE_TYPES = ['KML', 'KMZ'];
const GEO_JSON_FILE_TYPES = ['GEOJSON', 'KML', 'KMZ'];
const DOWNLOADABLE_LAYER_TYPES = ['PDF', 'PNG', 'JPG', 'JPEG'];
const OPACITY_ENABLED_FILE_TYPES = ['PDF', 'TIF', 'JPG', 'JPEG', 'PNG'];
const ZOOM_TO_ENABLED_FILE_TYPES = ['PDF', 'PNG', 'JPG', 'JPEG'];

export {
    KML_FILE_TYPES,
    GEO_JSON_FILE_TYPES,
    DOWNLOADABLE_LAYER_TYPES,
    OPACITY_ENABLED_FILE_TYPES,
    ZOOM_TO_ENABLED_FILE_TYPES
}

async function getPdfPageData(url: string) {
    // Load the PDF document
    const pdf = await PDFJS.getDocument(url).promise;

    // Get the first page (change the number to get other pages)
    const page = await pdf.getPage(1);
    // Get the viewport (using a high scale for high quality)
    const scale = 2.0; // Change this value to adjust quality
    const viewport = page.getViewport({ scale });

    // Extract width and height
    const { width, height } = viewport;

    // Render the page to a canvas and then extract it as an image URL
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    const renderContext = {
        canvasContext: context,
        viewport: viewport,
    };

    await page.render(renderContext).promise;
    const imageUrl = canvas.toDataURL();
    const adjustedWidth = width / scale;
    const adjustedHeight = height / scale;

    return { width: adjustedWidth, height: adjustedHeight, imageUrl };
}

function fileToArrayBuffer(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}

async function covertKmzToKml(kmzBuffer: ArrayBuffer) {
    const zip = await jszip.loadAsync(kmzBuffer)
    let kmlData;
    zip.forEach((relativePath, file) => {
        if (relativePath.endsWith(".kml")) {
            kmlData = file.async("string");
        }
    });

    if (!kmlData) {
        throw new Error("No KML file found inside the KMZ file.");
    }

    return kmlData;
}

type FileMapLayerData = {
    url: string | null,
    json: any | null,
    dimensions: { width: number, height: number }
}
export default function useFileMapLayerData({ enabled = true, file, fileType }: {
    enabled: boolean,
    file: File,
    fileType: string
}) {
    const [{ url, json, dimensions }, setData] = useState<FileMapLayerData>({ url: null, json: null, dimensions: { width: 0, height: 0 } });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
    }, [file])

    useEffect(() => {
        if (!IMAGE_EXTENSIONS.includes(fileType) || !enabled) return;

        const loadImageData = () => {
            let img = new Image();
            let url = URL.createObjectURL(file);

            img.onload = function () {
                const height = img.height,
                    width = img.width;
                const dimensions = { width, height };
                setData({ url, json: null, dimensions });
                setLoading(false);
            };

            img.src = url;
        };

        loadImageData();
    }, [enabled, fileType, file]);

    useEffect(() => {
        if (!file || fileType !== 'PDF' || !enabled) return;
        getPdfPageData(URL.createObjectURL(file)).then(
            ({ width, height, imageUrl: url }) => {
                setData({ url, json: null, dimensions: { width, height } });
                setLoading(false);
            }
        ).catch((error) => {
            alert('Oops! Unexpected Error loading PDF - please try again..\nIf dragging and dropping file, please try clicking to open file explorer instead.');
            Sentry.captureException(error);
            setLoading(false);
        });
    }, [enabled, file, fileType]);

    useEffect(() => {
        if (!KML_FILE_TYPES.includes(fileType) || !enabled) return;

        const convertXMLToJSON = async () => {
            let xmlString;
            if (fileType === 'KMZ') {
                let kmzBuffer = await fileToArrayBuffer(file) as ArrayBuffer;
                xmlString = await covertKmzToKml(kmzBuffer)
            }
            else xmlString = await toText(file)

            const dom = new DOMParser().parseFromString(xmlString, 'text/xml');
            const json = toGeoJson.kml(dom);

            const filteredFeatures = [];

            for (let feature of json.features) {
                if (feature.geometry.type === 'Point') continue;
                filteredFeatures.push(feature);
            }

            json.features = filteredFeatures;

            setData({ url: null, json, dimensions: { width: 0, height: 0 } });
            setLoading(false);
        };

        convertXMLToJSON();
    }, [enabled, fileType, file]);

    useEffect(() => {
        if (!TIFF_FILE_TYPES.includes(fileType) || !enabled) return;
        setLoading(false)
    }, [enabled, fileType, file]);

    return { data: { url, json, dimensions }, file, loading };

}
