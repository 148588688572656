import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjectSiteNote from './ProjectSiteNote';
import useAuth from '../../auth/useAuth';
import notesApi from '../../api/notes';
import useApi from '../../hooks/useApi';
import useDelayedSave from '../../hooks/useDelayedValue';
import useSaved from '../../hooks/useSaved';

function EditableProjectSiteNote({
    condensed = false,
    editable = true,
    dataTestId,
    note,
    dismissable,
    onDismiss,
    onEditButtonClicked,
    showEditButton = true,
    showPhotos,
    onShowPhotosClicked,
    maxHeight = ``,
}: {
    condensed?: boolean,
    editable?: boolean,
    note: any,
    dataTestId?: string,
    dismissable?: any,
    onEditButtonClicked?: () => void,
    onDismiss?: any,
    key?: any,
    showEditButton?: boolean,
    showPhotos?: any,
    onShowPhotosClicked?: any,
    maxHeight?: string,
}) {

    const { user } = useAuth();
    const { project_id } = useParams<{ project_id: string }>();
    const [text, setText] = useState<string | null>();

    const {
        data: savedNote,
        loading: saving,
        request: updateNote,
    } = useApi(notesApi.updateNote, null);

    const { value } = useDelayedSave(text);
    const { saved } = useSaved(savedNote?.updated_at)
    const onEdit = ({ target: { textContent: newText } }: { target: HTMLInputElement }) => setText(newText)

    useEffect(() => {
        if (!value) return;
        updateNote(note.objectId, { text: value })
    }, [value])

    return <ProjectSiteNote
        condensed={condensed}
        editable={editable && user?.id === note.notetaker.id}
        dataTestId={dataTestId}
        note={note}
        dismissable={dismissable}
        onDismiss={onDismiss}
        showEditButton={showEditButton}
        showPhotos={showPhotos}
        onShowPhotosClicked={onShowPhotosClicked}
        saved={saved}
        saving={saving}
        onEditButtonClicked={onEditButtonClicked}
        onEdit={onEdit}
        maxHeight={maxHeight}
    />
}

export default EditableProjectSiteNote;
