import { useEffect, useState } from 'react';
import MembersModal from './MembersModal';
import AddMembersModal from './AddMembersModal';
import QRCodeModal from './QRCodeModal';
import useApi from '../../hooks/useApi';
import projectMembersApi from '../../api/project_members';
import Loader from '../utility/Loader';
import useQuery from '../../hooks/useQuery';
import { useParams } from 'react-router-dom';
import ProjectMemberViewModel from '../../models/ProjectMemberViewModel';
import useFeature from '../feature_flags/hooks/useFeature';
import { BsQrCode } from 'react-icons/bs';
import Button from '../shared/Button';
import accessCodesApi from '../../api/access_codes';
import useWorkspaceContext from '../../hooks/useWorkspaceContext';

export default function MembersCard() {
  const { project_id } = useParams();
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadProjectMembers,
  } = useApi(
    projectMembersApi.getProjectMembers,
    { records: [], pagy: {} },
    true
  );
  let query = useQuery();
  const loadFirstPage = () => {
    loadProjectMembers(project_id, { items: 5, page: 1 });
  };
  useEffect(() => {
    loadFirstPage();
  }, []);

  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const { workspace } = useWorkspaceContext();

  const fetchAccessCode = async () => {
    try {
      const response = await accessCodesApi.getAccessCodes({ where: { active: true }, workspace_id: workspace.id });
      if (response.data.records && response.data.records.length > 0) {
        setAccessCode(response.data.records[0].code);
      }
    } catch (error) {
      console.error('Failed to fetch access code:', error);
    }
  };

  useEffect(() => {
    if (workspace) {
      fetchAccessCode();
    }
  }, [workspace]);

  const {
    enabled: qrCodeSharingEnabled
  } = useFeature('project_qrcode_sharing');

  return (
    <div className="bg-white rounded-sm border border-gray-200 overflow-hidden text-black mb-6 shadow-xl rounded-lg">
      <div className="flex flex-col h-full">
        <div className="card-header">
          <p className="text-sm font-bold">
            {pagy.count ? 'Project Members (' + pagy.count + ')' : '...'}
          </p>
          <div className="flex justify-end">
            {qrCodeSharingEnabled && (
              <Button
                data-testid="qrCodeSharingButton"
                onClick={() => setShowQRCodeModal(true)}
                text="Share QR Code"
                className="text-tertiary mr-2"
                size="xs"
                icon={<BsQrCode size={12} className="mr-2 text-tertiary" />}
                iconAligned="left"
                color="light"
              />
            )}
            <MembersModal
              open={query.get('show') === 'teamMembers'}
              afterUpdate={loadFirstPage}
            />
          </div>
        </div>
        {loading && records.length === 0 && (
          <div className="flex justify-center py-5">
            <Loader color="black" />
          </div>
        )}

        {(!loading || records.length > 0) && (
          <div className="grid grid-cols-6 gap-1 h-36 p-5 text-center text-xxs">
            {records.map((member) => {
              let projectMember = new ProjectMemberViewModel(member);
              return (
                <div
                  className="w-20 mr-5"
                  key={`project_member_${projectMember.objectId}`}
                >
                  <img
                    className="rounded-full border-2 border-white object-cover mx-auto w-16 h-16"
                    src={
                      projectMember.member.profile_photos?.medium ||
                      'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                    }
                    alt={projectMember.member.name}
                  />
                  <p className="mt-2 truncate">
                    {projectMember.member.name || projectMember.member.email}
                  </p>
                  {projectMember.invitationPending?.() && (
                    <p className="italic text-xxxs text-tertiary truncate">
                      Invitation pending
                    </p>
                  )}
                  <p className="font-bold text-xxxs truncate overflow-ellipsis">
                    {projectMember.member.title}
                  </p>
                </div>
              );
            })}

            <AddMembersModal afterUpdate={loadFirstPage} />
            {qrCodeSharingEnabled && (
              <QRCodeModal
                conference={workspace.conference}
                open={showQRCodeModal}
                onClose={() => setShowQRCodeModal(false)}
                accessCode={accessCode}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
