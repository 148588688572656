import { memo, useEffect, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import pinsApi from '../../api/pins';
import useApi from '../../hooks/useApi';
import useProject from '../../hooks/useProject';
import useQuery from '../../hooks/useQuery';
import { maxPinCount } from '../../utility/pinConfig';
import MapBoxMap from '../maps/mapbox/MapBoxMap';
import LayersList from '../map_layers/LayersList';
import NewPinModal from '../pins/NewPinModal';
import PinDropOverlay from '../pins/PinDropOverlay';
import PinModal from '../pins/PinModal';
import PinsList from '../pins/PinsList';
import PinExportButtons from '../reports/PinExportButtons';
import Button from '../shared/Button';
import Loader from '../utility/Loader';
import _ from 'lodash';
import { BoundingBox, Pin, PinFilterWhereParams, PinSearchParams } from '../../data/models';
import PinImportButton from '../pins/PinImportButton';
import PinCountBadge from '../maps/PinCountBadge';
import PinViewModel from '../../models/PinViewModel';

function ProjectMap() {
  const { project, loading: projectLoading } = useProject();
  const {
    data: { records: pins, pagy },
    loading,
    request: loadPins,
  } = useApi(pinsApi.getPins, { records: [], pagy: {} }, true); //arrow function then call getPins (3rd arg params project.map.bounds.find)

  const query = useQuery();
  const pinTarget = query.get('pin');

  const [searchParams, setSearchParams] = useState<PinSearchParams>({
    page: 1,
    items: maxPinCount,
    q: '',
    where: !!pinTarget ? undefined : {
      status: ['observation', 'needs_action']
    },
    full: true,
    order: 'updated_at desc',
  });
  const [mapViewDetail, setMapViewDetail] = useState('pins');
  const [mapState, setMapState] = useState<'default' | 'readyToDrop'>(
    'default'
  );
  const [pinDropPosition, setPinDropPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [pinDropModalShown, setPinDropModalShown] = useState(false);
  const [mapLayersOpen, setMapLayersOpen] = useState(false);
  const [shownLayers, setShownLayers] = useState([]);
  const [currentZoom, setCurrentZoom] = useState(18);
  const [selectedPin, setSelectedPin] = useState<Pin | PinViewModel | null>(null);
  const [deletedPinIds, setDeletedPinIds] = useState<string[]>([]);
  let { project_id } = useParams<{ project_id: string }>();


  const dropPin = (info: any) => {
    if (info) {
      setPinDropPosition({ lat: info.lngLat?.lat, lng: info.lngLat?.lng });
      setPinDropModalShown(true);
    }
  };

  const handleUserMapUpdatesMapbox = (bounds: BoundingBox) => {
    if (loading || selectedPin) return;
    const swCorner = [bounds.south, bounds.west];
    const neCorner = [bounds.north, bounds.east];
    const currentSw = searchParams?.within?.bounds?.sw_corner;
    const currentNe = searchParams?.within?.bounds?.ne_corner;

    if (_.isEqual(currentSw, swCorner) && _.isEqual(currentNe, neCorner))
      return;

    setSearchParams({
      ...searchParams,
      within: {
        bounds: {
          sw_corner: [bounds.south, bounds.west],
          ne_corner: [bounds.north, bounds.east],
        },
      },
    });
  };

  useEffect(() => {
    loadPins('project', project_id, searchParams);
  }, [JSON.stringify(searchParams)]);

  useEffect(() => {
    if (!loading) {

      if (pinTarget != null) {
        let _pins = pins.filter((pin: any) => {
          // Should this be pin.objectId rather than pin.id?
          // Just in case it's really supposed to be pin.id, I've temporarily
          // silenced the error with a cast to any.
          return pin.id == (pinTarget as any);
        });
        if (_pins.length > 0) {
          setSelectedPin(_pins[0]);

          if (searchParams.where && searchParams.where.id)
            setSearchParams({
              ...searchParams,
              where: { ...searchParams.where, id: null },
            });
        } else setSearchParams({ ...searchParams, where: { id: parseInt(pinTarget) } });
      }
    }
  }, [loading, pinTarget]);

  const onNewPinModalClose = async (wasSubmitted: boolean) => {
    setPinDropPosition(null);
    setSelectedPin(null);
    if (wasSubmitted) {
      await loadPins('project', project_id, searchParams);
    }
  };

  const onDeletePin = (pinObjectId: string) => {
    setPinDropPosition(null);
    setSelectedPin(null);
    const deletedPin = pins.find((x: any) => x.objectId === pinObjectId);

    if (!deletedPin) return;

    setDeletedPinIds((dps) => ([...dps, deletedPin.objectId]));
  };

  const onClickMapboxMap = (e: any) => {
    if (mapLayersOpen) setMapLayersOpen(false);
    if (mapState === 'readyToDrop') {
      dropPin(e);
      setMapState('default');
    }
  };

  return (
    <div className="grid grid-cols-4 overflow-hidden h-full">
      <div className="col-span-full absolute bg-white h-64 inset-x-0 bottom-0 md:relative md:col-span-1 md:h-full overflow-hidden flex flex-col">
        <div className="px-5 pt-5 bg-gray-100">
          <div className="grid grid-cols-2 text-center font-semibold bg-white rounded-md overflow-hidden text-xs shadow mb-2">
            <a
              className="cursor-pointer"
              onClick={() => setMapViewDetail('pins')}
            >
              <div
                className={`py-1 ${mapViewDetail === 'pins'
                  ? 'text-white bg-secondary'
                  : 'text-black bg-white'
                  }`}
              >
                Pins
              </div>
            </a>
            <a
              className="cursor-pointer"
              onClick={() => setMapViewDetail('layers')}
            >
              <div
                className={`py-1 ${mapViewDetail === 'layers'
                  ? 'text-white bg-secondary'
                  : 'text-black bg-white'
                  }`}
              >
                Layers
              </div>
            </a>
          </div>
        </div>
        {mapViewDetail === 'pins' && (
          <PinsList
            pins={pins}
            loading={loading}
            onSearch={(text: string) => {
              setSearchParams({ ...searchParams, q: text, page: 1 });
            }}
            onFilter={(newFilter: PinFilterWhereParams) => {
              setSearchParams({ ...searchParams, where: newFilter, page: 1 });
            }}
            onSelect={(pin: PinViewModel) => {
              setSelectedPin(pin);
            }}
            currentFilter={searchParams.where}
          />
        )}
        {mapViewDetail === 'layers' && <LayersList onSearch={undefined} />}
      </div>
      <div
        className="col-span-full md:col-span-3 h-full bg-gray-200 relative"
        id="projectMap"
      >
        {!projectLoading ? (
          <>
            <PinDropOverlay
              on={mapState === 'readyToDrop'}
              onCancel={() => setMapState('default')}
            />
            <PinCountBadge loading={loading} count={pagy.count} />
            {mapState !== 'readyToDrop' && (
              <div className="absolute z-50 m-4 rounded-md overflow-hidden right-0 flex">
                <PinExportButtons
                  project={project}
                  searchParams={searchParams}
                  loadingPins={false}
                  containerClass="mr-2"
                />
                <PinImportButton project={project} containerClass="mr-2" afterImport={() => loadPins('project', project_id, searchParams)} />
                {/* @ts-ignore */}
                <Button
                  text="Drop Pin"
                  color="light"
                  icon={<FaPlus className="ml-2" />}
                  onClick={() => setMapState('readyToDrop')}
                  data-testid="dropPinButton"
                />
              </div>
            )}
            {/* TODO: proper type sigs on MapBoxMap */}
            {/* @ts-ignore */}
            <MapBoxMap
              project={project}
              pins={pins.filter(({ objectId }: { objectId: string }) => !deletedPinIds.includes(objectId))}
              passedSelectedPin={selectedPin}
              mapState={mapState}
              onMapClick={onClickMapboxMap}
              defaultLayers={shownLayers}
              onLayersChosen={setShownLayers}
              onSelectPin={(objectId: string) => {
                const pin = pins.find((x: any) => x.objectId === objectId);
                if (!pin) return;
                setSelectedPin(pin);
              }}
              onBoundsUpdated={handleUserMapUpdatesMapbox}
            />
          </>
        ) : (
          <div className="flex py-4 justify-center">
            <Loader color="black" />
          </div>
        )}
        <PinModal
          pinId={selectedPin?.objectId}
          key={`pin_${selectedPin && selectedPin.objectId}_map`}
          onClose={() => {
            setSelectedPin(null);
          }}
          onDeletePin={(pinObjectId: string) => onDeletePin(pinObjectId)}
          pin={selectedPin}
        />
        {!projectLoading && pinDropModalShown && (
          <NewPinModal
            zoom={currentZoom}
            position={pinDropPosition}
            mapLayers={shownLayers}
            key={`pin_modal_${selectedPin?.objectId}`}
            onClose={(wasSubmitted: boolean) => {
              onNewPinModalClose(wasSubmitted);
              setPinDropPosition(null);
              setPinDropModalShown(false);
            }}
            projectId={project_id}
            existingPin={selectedPin}
          />
        )}
      </div>
    </div>
  );
}

export default memo(ProjectMap);
