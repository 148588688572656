import React, { useState, useEffect, useRef } from 'react';
import { BiClipboard } from 'react-icons/bi';
import { HiGlobe } from 'react-icons/hi';
import { useCopyToClipboard } from 'react-use';
import sharesApi from '../../api/shares';
import useApi from '../../hooks/useApi';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
import { QRCode } from 'react-qrcode-logo';
import { BsQrCode } from 'react-icons/bs';
import Button from '../shared/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../utility/Modal';
import { useReactToPrint } from 'react-to-print';
import logo from '../../images/icons/icon-circle-canvas.png';
import useFeatures from '../../hooks/useFeatures';

export default function ShareOptions({ shareable, shareableType }) {
  const {
    data: { records: shares, pagy },
    error: loadError,
    loading,
    request: getShares,
  } = useApi(sharesApi.getShares, { records: [], pagy: {} }, true);
  const [error, setError] = useState();

  const [publicShare, setPublicShare] = useState(false);
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();

  const { features } = useFeatures();
  const qrCodeSharingEnabled = features?.rollout?.qrcode_sharing;

  const createPublicShare = async () => {
    setPublicShare(true);
    let { data } = await sharesApi.addShare({
      is_public: true,
      shareable_type: shareableType,
      shareable_id: shareable.id,
      share_types: ['public_link'],
    });
    setError(data.errors?.length ? data.errors[0] : false);
    setPublicShare(!data.errors?.length ? data : false);
  };

  const archivePublicShare = async () => {
    let { objectId } = publicShare;
    setPublicShare(false);
    await sharesApi.archiveShare(objectId);
  };

  useEffect(() => {
    if (!shareable?.asset && !shareable?.objectId) return;
    getShares({
      where: {
        shareable_type: shareableType,
        shareable_id: shareable.id,
        is_public: true,
      },
    });
  }, [shareable?.asset?.objectId, shareable?.objectId]);

  useEffect(() => {
    if (loading) return;
    const publicShare = shares.find(({ is_public }) => is_public);
    setPublicShare(publicShare);
  }, [shares, loading]);


  if (loading)
    return (
      <div
        data-testid="loader-share-options"
        className="flex justify-center w-full px-5 py-1 pb-2"
      >
        <Loader color={'text-gray-800'} />
      </div>
    );

  return (
    <>
      {error && <ErrorView error={error} extraClass={'relative ml-0'} />}
      <div className="flex bg-white p-3 shadow mb-1 justify-between">
        <div className="flex items-center">
          <p
            className={`mr-2 ${publicShare ? 'text-tertiary' : 'text-gray-500'
              } font-medium text-sm flex items-center cursor-pointer`}
            title="Allows anyone with link to view/download the pdf."
          >
            <HiGlobe size={20} className="mr-1" /> Public Link
          </p>
        </div>
        <div className="flex items-center">
          {qrCodeSharingEnabled && <Button
            data-testid="qrCodeSharingButton"
            onClick={() => setShowQRCodeModal(true)}
            text={<div className="flex justify-center items-center">Printable QRCode</div>}
            className='text-tertiary mr-2'
            size='xs'
            icon={<BsQrCode size={12} className="mr-2 text-tertiary" />}
            iconAligned='left' color='light'
          />}
          {publicShare && (
            <button
              onClick={() => copyToClipboard(`${publicShare.link}?utm_source=copied_link`)}
              className="flex items-center text-xs rounded-md border border-gray-200 text-tertiary py-0.5 font-semibold px-3 shadow-sm hover:opacity-80 cursor-pointer focus:outline-none mr-2"
            >
              <BiClipboard className="mr-1" />{' '}
              {state.value ? 'Copied!' : 'Copy Link'}
            </button>
          )}
          <div
            className={`form-switch focus-within:shadow-outline mr-3`}
            role="switch"
            data-testid="publicLinkToggle"
            onClick={publicShare ? archivePublicShare : createPublicShare}
          >
            <input
              type="checkbox"
              id={`publicLink_${shareable.objectId}`}
              className="sr-only"
              checked={publicShare}
              onChange={() => { }}
            />
            <label
              className="bg-gray-300"
              htmlFor={`publicLink_${shareable.objectId}`}
            >
              <span className="bg-white shadow-sm" aria-hidden="true"></span>
              <span className="sr-only">Public Link</span>
            </label>
          </div>
        </div>
      </div>
      {qrCodeSharingEnabled && <QRCodeModal open={showQRCodeModal} onClose={() => setShowQRCodeModal(false)} link={publicShare?.link} />}
    </>
  );
}

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components

const QRCodeToPrint = React.forwardRef((props, ref) => {
  return (
    <div className="flex justify-center mb-4 py-5" data-testid="qrCodeContainer" ref={ref}>
      <div className="border p-2 rounded-md shadow">
        <QRCode
          value={`${props.link}?utm_source=qr_code`}
          size={256}
          logoImage={logo}
          logoWidth={50}
          logoHeight={50}
          logoPadding={8}
          quietZone={20}
          logoPaddingStyle="circle"
          removeQrCodeBehindLogo
        />
      </div>
    </div>
  );
});

export {
  QRCodeToPrint
};

const QRCodeModal = ({
  link,
  onClose,
  open
}) => {

  const componentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader title="QR Code" onClose={onClose} />
      <ModalBody>
        <QRCodeToPrint link={link} ref={componentToPrint} />
        <div className="flex flex-col items-center justify-center">
          <p className="font-medium text-secondary mb-4">Print this QR code and use it on site to <em>link directly to reports and documents.</em> </p>
          <Button data-testid="qrCodePrintButton" text="Print QR Code" color="light" onClick={handlePrint} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button text="Close" onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
}
