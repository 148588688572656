import { FiX } from 'react-icons/fi';
import placeholder from '../../images/placeholders/banner-white.png';
import moment from 'moment-timezone';
import Button from '../shared/Button';
import { MdEditNote } from 'react-icons/md';
import useAuth from '../../auth/useAuth';
import { IoMdEyeOff } from 'react-icons/io';
const BackgroundGradient = () => <div className="bg-gradient-to-b from-transparent to-black w-full absolute inset-0" />
const CloseButton = ({ onClick }) => <div data-testId="pinModalCloseButton" className="absolute inset-0 p-4 text-white w-24 h-24 z-10" onClick={onClick}>
  <FiX size={34} className="hover:opacity-60 cursor-pointer" />
</div>
const RightContainer = ({ children }) => <div className="absolute top-3 right-3 flex items-center">{children}</div>
const PinHeaderFooter = ({ children }) => <div className="absolute w-full bottom-0 p-4 text-white">{children}</div>

export default function PinHeader({ pin, onClose, onEditPin }) {

  const { user } = useAuth();


  return (
    <div className="relative">
      <img
        className="w-full object-cover h-56 bg-gray-100"
        src={pin?.photoed_note?.processed_photos?.[0]?.large || placeholder}
        alt="Pin Photo"
      />
      <BackgroundGradient />
      <CloseButton
        onClick={onClose}
      />
      <RightContainer>
        {
          pin.private && <IoMdEyeOff title='Private pin' size={25} className="mr-3 text-gray-500" />
        }
        {pin.canEditPinDrop() &&
          <Button text="Edit Pin Drop" data-testId="editPinDropButton" onClick={onEditPin} color="dark" icon={<MdEditNote size={20} className="mr-2" />} iconAligned='left' />
        }
      </RightContainer>
      <PinHeaderFooter>
        <div className="flex justify-between mb-3 items-center">
          <p className="text-3xl font-bold" data-testId="pinHeaderIdentifier">{pin.identifier}</p>
          <p
            className="text-lg py-2 px-3 rounded-lg capitalize"
            style={{ backgroundColor: pin.getStatusColor() }}
            data-testId="pinHeaderStatus"
          >
            {pin.status?.replace(/_/g, ' ')}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-lg font-medium" data-testId="pinHeaderType">{pin.pin_type.name}</p>
          <p className="text-md">
            Last updated {moment(pin.updated_at).format('MMM D, YYYY')}
          </p>
        </div>
      </PinHeaderFooter>
    </div>
  );
}
