import Page, { PageHeader, PageTitle } from '../shared/Page';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import useProjectDownloads from './hooks/useProjectDownloads';
import Loader from '../utility/Loader';
import EmptyState from '../utility/EmptyState';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSuccessView } from '../utility/Modal';
import useModal from '../../hooks/useModal';
import useProject from '../../hooks/useProject';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DownloadStatus from '../downloads/DownloadStatus';
import { FiDownload, FiTrash2 } from 'react-icons/fi';
import useQuery from '../../hooks/useQuery';
import ErrorView from '../utility/ErrorView';
export default function ProjectDownloads() {

    const { project_id } = useParams<{ project_id: string }>();
    const query = useQuery();

    const { project } = useProject();
    const [newDownloadName, setNewDownloadName] = useState(`${project?.identifier}_${project?.name}_${moment().format('MM-DD-YYYY')}`);
    const [deletingDownload, setDeletingDownload] = useState<any | null>(null);

    const { open, setOpen } = useModal();
    const activeDownloadId = query.get('download_id');

    const {
        downloads,
        loading,
        loadDownloads,
        createDownload,
        creating,
        createError,
        created,
        deleteDownload,
        deleted,
        deleting,
    } = useProjectDownloads(project_id);

    useEffect(() => {
        setNewDownloadName(`${project?.identifier}_${project?.name}_${moment().format('MM-DD-YYYY')}`);
    }, [project?.updated_at, open])

    useEffect(() => {
        if (!created && !deleted) return;
        setTimeout(() => {
            setDeletingDownload(null);
            setOpen(false);
            loadDownloads();
        }, 1000);
    }, [created, deleted])

    return <Page>
        <PageHeader>
            <PageTitle>Downloads</PageTitle>
            <div className="flex justify-end">
                <a className="cursor-pointer" onClick={() => setOpen(true)}>
                    <div className="btn rounded hover:text-gray-50 hover:opacity-90 text-white bg-secondary">
                        <svg
                            className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                            viewBox="0 0 16 16"
                        >
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span className="hidden xs:block ml-2">Create Download</span>
                    </div>
                </a>
            </div>
        </PageHeader>
        <DataTable
            progressPending={loading}
            progressComponent={<div className="flex py-4 justify-center">
                <Loader color="black" />
            </div>}
            conditionalRowStyles={[
                {
                    when: (row: any) => activeDownloadId ? row.id === parseInt(activeDownloadId) : false,
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    }
                }]}
            noDataComponent={<EmptyState
                heading={'Create download'}
                subHeading={'There are no downloads for this project yet.'}
                hasButton
                onClick={() => setOpen(true)}
                buttonText='Create download'
            />}
            columns={defaultColumns((id) => setDeletingDownload(id))}
            data={downloads}
        />
        <CreateDownloadModal
            error={createError}
            creating={creating}
            created={created}
            defaultName={newDownloadName}
            open={open}
            onNameUpdate={setNewDownloadName}
            onCreate={() => createDownload({ name: newDownloadName })}
            onClose={() => setOpen(false)}
        />
        <DeleteDownloadModal
            deleted={deleted}
            deleting={deleting}
            open={!!deletingDownload}
            onClose={() => setDeletingDownload(null)}
            onConfirmation={() => deleteDownload(deletingDownload.id)}
        />
    </Page>
}

const CreateDownloadModal = ({
    creating,
    created,
    defaultName,
    error,
    onCreate,
    onNameUpdate,
    open,
    onClose,
}: {
    defaultName: string,
    creating: boolean,
    created: boolean,
    error: any,
    open: boolean,
    onCreate: () => void,
    onNameUpdate?: (name: string) => void,
    onClose: () => void,
}) => {
    return <Modal isOpen={open} onClose={onClose}>
        <ModalHeader title="Start Project Download" onClose={onClose} />
        <ModalBody>
            {error && <ErrorView error={error} />}
            {created ? <ModalSuccessView text={'Download successfully started'} /> :
                <>
                    <div className="mb-2 font-semibold">Ready to create a project download?</div>
                    <div className="mb-4 font-light text-secondary text-sm">We'll start processing your download and email you when it's ready.</div>
                    <div className="mb-4">
                        <div className="font-light mb-2 text-sm">File Name</div>
                        <div className="bg-white flex shadow-sm border rounded-md border-gray-200 overflow-hidden">
                            <div className="flex flex-grow items-center">
                                <input
                                    type="text"
                                    data-testid="downloadNameInput"
                                    defaultValue={defaultName}
                                    onChange={({ target: { value } }) =>
                                        onNameUpdate?.(value)
                                    }
                                    className="px-2 bg-white placeholder-gray-500 relative text-sm border-0 w-full outline-none focus:outline-none focus:ring-0"
                                />
                            </div>
                            <div className="flex px-5 items-center bg-white border-l">
                                .zip
                            </div>
                        </div>
                    </div>
                </>}
        </ModalBody>
        {!created && <ModalFooter>
            <button
                className="modal-close-btn"
                type="button"
                onClick={onClose}
                disabled={creating}
            >
                Cancel
            </button>
            <button
                className="modal-save-btn"
                type="button"
                onClick={onCreate}
                disabled={creating}
            >
                Start Download {creating && <Loader />}
            </button>
        </ModalFooter>}
    </Modal>
}

const DeleteDownloadModal = ({
    deleting,
    deleted,
    open,
    onClose,
    onConfirmation,
}: {
    deleting: boolean,
    deleted: boolean,
    open: boolean,
    onClose: () => void,
    onConfirmation: () => void,
}) => {
    return <Modal isOpen={open} onClose={onClose}>
        <ModalHeader title="Delete Download" onClose={onClose} />
        <ModalBody>
            {deleted ? <ModalSuccessView text={'Download successfully deleted'} /> : <>
                <div className="mb-2 font-semibold">Are you sure you want to delete this download?</div>
                <div className="mb-4 font-light text-red-800 text-sm">This action cannot be undone.</div>
            </>}
        </ModalBody>
        {!deleted && <ModalFooter>
            <button
                className="modal-close-btn"
                type="button"
                onClick={onClose}
                disabled={deleting}
            >
                Cancel
            </button>
            <button
                className="modal-remove-btn"
                type="button"
                data-testid="confirmDelete"
                onClick={onConfirmation}
                disabled={deleting}
            >
                Delete {deleting && <Loader />}
            </button>
        </ModalFooter>}
    </Modal>
}

const defaultColumns = (onDeleteChosen: (download: any) => void) => [
    {
        name: 'Name',
        wrap: true,
        cell: (row: any) => <span className="font-semibold">{row.name}</span>,
        conditionalCellStyles: [
            {
                when: (row: any) => row.name,
            },
        ],
        selector: (row: any) => row.name,
        sortable: true,
        sortField: 'name',
    },
    {
        name: 'Creator',
        cell: (row: any) => <div className="flex items-center text-xs"><div
            className="rounded-full border-2 border-white box-content mr-1"
            style={{
                height: 24,
                width: 24,
                backgroundImage: `url(${row.creator.profile_photos?.medium ||
                    'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                    })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        />{row.creator.name}</div>,
        selector: (row: any) => row.creator.name,
        sortable: true,
        conditionalCellStyles: [
            {
                when: (row: any) => row.creator.name,
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                },
            },
        ],
    },
    {
        name: 'Created',
        selector: (row: any) => moment(row.created_at).format('MMM DD, yyyy h:mm A'),
        sortable: true,
        sortFunction: (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
        center: true,
        name: 'Status',
        cell: (row: any) => <DownloadStatus download={row} />,
    },
    {
        center: true,
        name: 'Actions',
        cell: (row: any) => {
            const downloadComplete = row.status === 'processed';
            return [<a
                data-testid="downloadLink"
                download={`${row.name}.zip`}
                className={`flex items-center focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 ${downloadComplete ? 'cursor-pointer' : 'cursor-not-allowed'} border-r`}
                href={downloadComplete ? row.asset.files.download.original : '#'}
            >
                Download <FiDownload className="ml-2" />
            </a>,
            <a
                data-testid="deleteLink"
                className={`flex items-center focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 ${downloadComplete ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                href={'#'}
                onClick={() => onDeleteChosen(row)}
            >
                <FiTrash2 />
            </a>]
        }
    },
];