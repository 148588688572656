const BADGE_STYLES = {
  gray: {
    bg: 'bg-gray-100',
    border: 'border-gray-400',
    text: 'text-gray-800',
    bgAnimate: 'bg-gray-400',
    bgCircle: 'bg-gray-500',
  },
  info: {
    bg: 'bg-blue-100',
    border: 'border-blue-400',
    text: 'text-blue-800',
    bgAnimate: 'bg-blue-400',
    bgCircle: 'bg-blue-500',
  },
  green: {
    bg: 'bg-green-100',
    border: 'border-green-400',
    text: 'text-green-800',
    bgAnimate: 'bg-green-400',
    bgCircle: 'bg-green-500',
  },
  warning: {
    bg: 'bg-yellow-100',
    border: 'border-yellow-400',
    text: 'text-yellow-800',
    bgAnimate: 'bg-yellow-400',
    bgCircle: 'bg-yellow-500',
  },
  danger: {
    bg: 'bg-red-100',
    border: 'border-red-400',
    text: 'text-red-800',
    bgAnimate: 'bg-red-400',
    bgCircle: 'bg-red-500',
  },
};

const Dot = ({
  style = 'warning',
  title = 'Badge',
  animated = false,
  onClick = undefined,
  ...rest
}: {
  style?: 'info' | 'green' | 'warning' | 'danger' | 'gray',
  title?: string,
  animated?: boolean,
  onClick?: () => void
}) => {
  const styleClass = BADGE_STYLES[style];

  return <span className={`flex h-3 w-3 relative ${!!onClick ? 'cursor-pointer' : ''}`} title={title} onClick={onClick} {...rest}>
    {animated && (
      <span
        className={`animate-ping absolute inline-flex h-full w-full rounded-full ${styleClass.bgAnimate} opacity-75`}
      ></span>
    )}
    <span
      className={`relative inline-flex rounded-full h-3 w-3 ${styleClass.bgCircle} `}
    ></span>
  </span>;
}

export {
  Dot
};

export default function Badge({
  animated = false,
  dot = true,
  style = 'warning',
  label = 'Processing',
  title = 'Map layer is currently processing to full resolution',
  margin = 'mx-1',
  padding = 'py-1 px-2',
}: {
  animated?: boolean,
  dot?: boolean,
  style?: 'info' | 'green' | 'warning' | 'danger',
  label?: string,
  title?: string,
  margin?: string,
  padding?: string
}) {
  const styleClass = BADGE_STYLES[style];
  return (
    <div
      className={`text-xs ${styleClass.text} flex items-center ${styleClass.bg} border rounded-full ${styleClass.border} ${margin} ${padding}`}
      title={title}
    >
      {label && <span className="mr-1">{label}</span>}
      {dot && <Dot title={title} animated={animated} style={style} />}
    </div >
  );
}
