import { useEffect, useState } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';

import ProjectMap from '../partials/project/ProjectMap';
import Loader from '../partials/utility/Loader';
import ConstructionDrawings from '../partials/construction_drawings/ConstructionDrawings.js';
import Reports from '../partials/reports/Reports';
import ProjectSettings from '../partials/project/ProjectSettings';
import ProjectDetails from '../partials/project/ProjectDetails';
import useProject from '../hooks/useProject';
import ProjectDocuments from '../partials/project/ProjectDocuments';
import useFeatures from '../hooks/useFeatures';
import bytesToSize from '../utility/bytesToSize';
import Tags from '../partials/shared/Tags';
import tagsApi from '../api/tags';
import MapboxMapLayerNewRefactor, {
  MapboxMapLayerEdit,
} from '../partials/map_layers/MapboxMapLayerNewRefactor';
import ProjectDownloads from '../partials/project/ProjectDownloads';

export default function Project() {
  const { project, error, loading, setProject } = useProject();
  const { project_id, workspace_id, path, map_layer_id } = useParams();
  const history = useHistory();
  const [activePane, setActivePane] = useState(path || 'details');
  const { features } = useFeatures();
  const projectStorageEnabled = features.rollout?.project_storage_enabled;
  const onUpdateTag = async (tag) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.updateTagOnProject(project_id, tag.id, tag);
    setProject((project) => ({
      ...project,
      tags_with_colors,
    }));
  };

  const onAddTag = async (tag) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.addTagsToProject(project_id, tag.name);
    setProject((project) => ({
      ...project,
      tags_with_colors,
    }));
  };

  const onDestroyTag = async (tag) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.destroyTagOnProject(project_id, tag.id);

    setProject((project) => ({
      ...project,
      tags_with_colors,
    }));
  };

  const onRemoveTag = async (tag) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.removeTagsFromProject(project_id, tag.name);

    setProject((project) => ({
      ...project,
      tags_with_colors,
    }));
  };

  const updateHistory = (newPath) => {
    history.push(`/${workspace_id}/projects/${project_id}/${newPath}`);
  };

  useEffect(() => {
    if (activePane !== path) setActivePane(path);
  }, [path]);

  useEffect(() => {
    if (activePane !== path)
      history.push(`/${workspace_id}/projects/${project_id}/${activePane}`);
  }, [activePane]);

  if (error) {
    return (
      <Redirect
        to={{
          pathname: `/${workspace_id}/projects`,
          state: { error: error.text?.() || 'Unexpected error has occured' },
        }}
      />
    );
  }

  return (
    <>
      {loading || !project ? (
        <div className="flex py-4 justify-center">
          <Loader color="black" />
        </div>
      ) : (
        <div className={activePane.includes('map_layer') ? 'hidden' : ''}>
          <div className="hidden md:flex px-4 sm:px-6 lg:px-8 py-8 bg-white mb-4 sm:mb-0 justify-between items-center">
            <h1 className="text-2xl md:text-3xl text-gray-800 uppercase font-light font-oswald subpixel-antialiased">
              {project && (
                <>
                  <span className="font-medium">{project.identifier}</span>{' '}
                  {project.name}
                </>
              )}
              <Tags
                tags={project.tags_with_colors}
                keyPrefix={project_id}
                containerClassName="normal-case font-normal font-inter items-center flex mt-2"
                maxTags={0}
                tagSize="md"
                canAdd={true}
                onAdd={onAddTag}
                onDestroy={onDestroyTag}
                onRemove={onRemoveTag}
                onUpdate={onUpdateTag}
              />
            </h1>
            {projectStorageEnabled && project.metadata?.storage_size && (
              <div className="font-semibold border rounded-lg border-gray-200 px-2 py-1 text-sm">
                {bytesToSize(project.metadata?.storage_size)}
              </div>
            )}
          </div>
          <div className="hidden md:flex px-4 sm:px-6 lg:px-8 bg-white border-b-2 h-12 border-gray-200">
            <a
              className={`cursor-pointer h-full px-5 flex items-center font-bold rounded-t-lg text-secondary ${activePane === 'details'
                ? 'bg-gray-100 border-l border-r border-t border-gray-200 mt-0.5'
                : 'bg-white hover:bg-gray-100 hover:mt-0.5'
                }`}
              onClick={() => updateHistory('details')}
            >
              Details
            </a>
            <a
              className={`cursor-pointer h-full px-5 flex items-center font-bold rounded-t-lg text-secondary ${activePane === 'maps'
                ? 'bg-gray-100 border-l border-r border-t border-gray-200 mt-0.5'
                : 'bg-white hover:bg-gray-100 hover:mt-0.5'
                }`}
              onClick={() => updateHistory('maps')}
            >
              Maps
            </a>
            <a
              className={`cursor-pointer h-full px-5 flex items-center font-bold rounded-t-lg text-secondary ${activePane === 'reports'
                ? 'bg-gray-100 border-l border-r border-t border-gray-200 mt-0.5'
                : 'bg-white hover:bg-gray-100 hover:mt-0.5'
                }`}
              data-testid="projectReportsTab"
              onClick={() => updateHistory('reports')}
            >
              Reports
            </a>
            <a
              className={`cursor-pointer h-full px-5 flex items-center font-bold rounded-t-lg text-secondary ${activePane === 'cds'
                ? 'bg-gray-100 border-l border-r border-t border-gray-200 mt-0.5'
                : 'bg-white hover:bg-gray-100 hover:mt-0.5'
                }`}
              onClick={() => updateHistory('cds')}
            >
              CD's
            </a>
            {features.rollout?.document_management_system &&
              project.permissions?.can?.('view_documents') && (
                <a
                  className={`cursor-pointer h-full px-5 flex items-center font-bold rounded-t-lg text-secondary ${activePane === 'documents'
                    ? 'bg-gray-100 border-l border-r border-t border-gray-200 mt-0.5'
                    : 'bg-white hover:bg-gray-100 hover:mt-0.5'
                    }`}
                  data-testid="projectDocumentsTab"
                  onClick={() => updateHistory('documents')}
                >
                  Documents
                </a>
              )}
            {project.permissions?.can?.('destroy') && (
              <a
                className={`cursor-pointer h-full px-5 flex items-center font-bold rounded-t-lg text-secondary ${activePane === 'settings'
                  ? 'bg-gray-100 border-l border-r border-t border-gray-200 mt-0.5'
                  : 'bg-white hover:bg-gray-100 hover:mt-0.5'
                  }`}
                onClick={() => updateHistory('settings')}
              >
                Settings
              </a>
            )}
            {features.rollout?.project_downloads && project.permissions?.can?.('create_downloads') && (
              <a
                className={`cursor-pointer h-full px-5 flex items-center font-bold rounded-t-lg text-secondary ${activePane === 'downloads'
                  ? 'bg-gray-100 border-l border-r border-t border-gray-200 mt-0.5'
                  : 'bg-white hover:bg-gray-100 hover:mt-0.5'
                  }`}
                onClick={() => updateHistory('downloads')}
              >
                Downloads
              </a>
            )}
          </div>
        </div>
      )}
      {!loading && project && (
        <>
          {activePane === 'map_layers' && (
            <>
              {map_layer_id === 'new' ? (
                <MapboxMapLayerNewRefactor project={project} />
              ) : (
                <MapboxMapLayerEdit project={project} />
              )}
            </>
          )}

          {activePane === 'details' && <ProjectDetails />}

          {activePane === 'maps' && <ProjectMap />}

          {activePane === 'reports' && <Reports />}

          {activePane === 'cds' && <ConstructionDrawings />}

          {activePane === 'documents' &&
            features.rollout?.document_management_system &&
            project.permissions?.can?.('view_documents') && (
              <ProjectDocuments />
            )}

          {activePane === 'settings' &&
            project.permissions?.can?.('destroy') && <ProjectSettings />}

          {activePane === 'downloads' && project.permissions?.can?.('create_downloads') && <ProjectDownloads />}
        </>
      )}
    </>
  );
}
