import { memo, useCallback, useEffect, useRef, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSuccessView } from '../utility/Modal';
import ErrorView from '../utility/ErrorView';
import ErrorViewModel from '../../models/ErrorViewModel';
import { GoPlus } from 'react-icons/go';
import Loader from '../utility/Loader';
import AddPhotoItem from '../project/AddPhotoItem';
import { useParams } from 'react-router';
import Form from '../../utils/Form';
import PhotosModal from '../utility/PhotosModal';
import MapFileToAsset from '../assets/utils/MapFileToAsset';
import useNoteCreate from './hooks/useNoteCreate';
import timings from '../../styles/timings';

function NoteCreateModal({
  includePhotos = true,
  afterAdd,
  onClose,
  open,
}: {
  includePhotos?: boolean,
  afterAdd?: (n: any) => void,
  onClose: () => void,
  open?: boolean,
}) {

  const { project_id } = useParams<{ project_id: string }>();
  const { created, note, error, creating, createNote } = useNoteCreate();
  const [saving, setSaving] = useState(false);
  const [savedPhotos, setSavedPhotos] = useState<string[]>([]);
  const [description, setDescription] = useState<string | undefined>();
  const [files, setFiles] = useState<any[]>([]);
  const [shownPhotoIndex, setShownPhotoIndex] = useState(-1);
  const closeModalTimer = useRef<NodeJS.Timeout>();

  const addNewNote = async () => {
    await createNote('project', project_id, {
      text: description,
      assets_attributes: savedPhotos.map((id) => {
        return { file: id };
      }),
    });
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const updateDescription = (text: string) => {
    setDescription(text);
  };

  const removeImageAtIndex = (index: number) => {
    setFiles([...files.filter((f, i) => i !== index)]);
  };

  const savePhotos = (signedId: string) => {
    setSavedPhotos([...savedPhotos, signedId]);
  };

  const resetModal = () => {
    setFiles([]);
    setSaving(false);
    setDescription(undefined);
  };

  const closeModal = () => {
    if (closeModalTimer.current) clearTimeout(closeModalTimer.current);
    onClose?.();
  }

  useEffect(() => {
    if (
      (savedPhotos.length > 0 && savedPhotos.length === files.length) ||
      (saving && files.length === 0)
    )
      addNewNote();
  }, [savedPhotos, saving]);

  useEffect(() => {
    if (!created) return;
    afterAdd?.(note);
    resetModal();
    closeModalTimer.current = setTimeout(() => {
      closeModal();
    }, timings.normal);
  }, [created]);

  return (
    <>
      <Modal isOpen={open} onClose={closeModal}>
        <ModalHeader
          title="Add Site Note"
          onClose={closeModal}
          closeBtnTestId="buttonCloseNoteCreateModal"
          sticky
        />
        {error && <ErrorView error={new ErrorViewModel(error)} />}
        {created ? (
          <ModalSuccessView text={'Site Note successfully added to project'} />
        ) : (
          <>
            <ModalBody>
              <>
                <Form.Group>
                  <Form.TextArea
                    mb={0}
                    placeholder="Add a note ..."
                    value={description}
                    rows={4}
                    onChange={({ target: { value } }) =>
                      updateDescription(value)
                    }
                    data-testid="inputAddSiteNote"
                  />
                </Form.Group>
              </>
              <div className="pb-2 flex flex-wrap">
                {files.map((file, index) => {
                  return (
                    <AddPhotoItem
                      key={`project_${project_id}_temp_site_note_image_${file.path}`}
                      file={file}
                      startSaving={saving}
                      index={index}
                      onSaved={savePhotos}
                      passback={true}
                      description={description}
                      onSelect={() => setShownPhotoIndex(index)}
                      onRemove={removeImageAtIndex}
                      locationRequired={undefined}
                      onLocated={undefined}
                    />
                  );
                })}
              </div>
              {includePhotos && <Form.FileDropzone dropzoneTestId='addNoteModalPhotosInput' accept="image/jpeg, image/png" onDrop={onDrop} />}
            </ModalBody>

            <ModalFooter sticky>
              <button
                className="modal-save-btn"
                type="button"
                data-testid="buttonCreateSiteNote"
                disabled={!description || creating}
                onClick={() => setSaving(true)}
              >
                Save
                {creating && <Loader className="ml-3" />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={creating}
                onClick={() => {
                  closeModal();
                  resetModal();
                }}
              >
                Cancel
              </button>
            </ModalFooter>
          </>
        )}
      </Modal>
      <PhotosModal
        isOpen={shownPhotoIndex > -1}
        assets={files.map(MapFileToAsset)}
        activeIndex={shownPhotoIndex}
        hideBackdrop
        onClose={() => setShownPhotoIndex(-1)}
      />
    </>
  );
}

const NoteAddButton = ({ onClick }: {
  onClick: () => void
}) => <a
  className="bg-secondary rounded-md flex px-2 py-1 text-base items-center cursor-pointer hover:opacity-80 shadow-sm"
  onClick={onClick}
>
    <GoPlus size={16} className="mr-1 mx-auto text-white" />
    <p className="font-bold text-white text-center text-xs">Add Note</p>
  </a>

export {
  NoteAddButton
}

export default NoteCreateModal;
