import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import pinTypesApi from '../../api/pin_types';
import pinsApi from '../../api/pins';
import { maxPinCount } from '../../utility/pinConfig';
import PinModal from './PinModal';

const PinContext = React.createContext();
export default PinContext;

export function PinContextProvider({ children }) {
  const {
    data: pin_types,
    loading: loadingPinTypes,
    request: getProjectPinTypes,
  } = useApi(pinTypesApi.getProjectPinTypes, []);

  const {
    data: { records, pagy: pinsPagy },
    loading: loadingPins,
    source,
    request: getPinsRequest,
  } = useApi(pinsApi.getPins, { records: [], pagy: {} }, true);

  const { project_id, report_id, share_id, shareable_id } = useParams();

  const [loadPins, setLoadPins] = useState(false);
  const [pinFilter, setPinFilter] = useState({});
  const [excludedPins, setExcludedPins] = useState([]);
  const [pinSearchParams, setPinSearchParams] = useState({
    page: 1,
    items: maxPinCount,
    full: true,
    q: '',
    ...pinFilter,
  });
  const [shownPin, setShownPin] = useState(null);
  const [reporting, setReporting] = useState(false);
  const [showPins, setShowPins] = useState(false);
  const [pinTypes, setPinTypes] = useState([]);
  const [selectedPin, setSelectedPin] = useState();
  const [pins, setPins] = useState([]);

  const objectEmpty = (obj) => Object.keys(obj).length === 0;
  const pinIncludedOnReport = (pinId) => !excludedPins.includes(pinId);
  const updatePinInclusionOnReport = (pinId, include) =>
    setExcludedPins(
      include
        ? excludedPins.filter((id) => id !== pinId)
        : [...excludedPins, pinId]
    );

  useEffect(() => {
    if (!objectEmpty(pinFilter)) {
      setPinSearchParams((existing) => ({ ...existing, ...pinFilter }));
    }
  }, [JSON.stringify(pinFilter)]);

  useEffect(() => {
    getProjectPinTypes(project_id);
  }, []);

  useEffect(() => {
    if (pin_types.length === 0) return;
    setPinTypes(pin_types);
  }, [JSON.stringify(pin_types.map(({ objectId }) => objectId))]);

  const getPins = () => {
    if (report_id || shareable_id) {
      getPinsRequest('report', report_id || shareable_id, {
        ...pinSearchParams,
        share_id,
      });
    } else getPinsRequest('project', project_id, pinSearchParams);
  };

  useEffect(() => {
    if (loadPins) getPins();
    return () => source?.cancel();
  }, [
    loadPins,
    pinSearchParams.where,
    pinSearchParams.or_where,
    pinSearchParams.where_not,
  ]);

  useEffect(() => {
    setPins(records);
  }, [records?.map(({ objectId }) => objectId).join(',')]);

  const value = useMemo(() => {
    return {
      pinFilter,
      setPinFilter,
      pinSearchParams,
      setPinSearchParams,
      shownPin,
      setShownPin,
      showPins,
      setShowPins,
      selectedPin,
      setSelectedPin,
      reporting,
      setReporting,
      pinsPagy,
      pins,
      setPins,
      getPins,
      loadPins,
      setLoadPins,
      loadingPins,
      excludedPins,
      setExcludedPins,
      updatePinInclusionOnReport,
      pinIncludedOnReport,
      pinTypes,
      loadingPinTypes,
    };
  }, [
    pinFilter,
    setPinFilter,
    pinSearchParams,
    setPinSearchParams,
    shownPin,
    setShownPin,
    showPins,
    setShowPins,
    selectedPin,
    setSelectedPin,
    reporting,
    setReporting,
    pins,
    setPins,
    loadPins,
    setLoadPins,
    loadingPins,
    excludedPins,
    setExcludedPins,
    updatePinInclusionOnReport,
    pinIncludedOnReport,
    pinTypes,
    loadingPinTypes,
  ]);

  return (
    <PinContext.Provider value={value}>
      {children}
      <PinModal
        key={`pin_${selectedPin && selectedPin.objectId}_list`}
        onClose={() => setSelectedPin(null)}
        pinId={selectedPin?.objectId}
        actionable={false}
      />
    </PinContext.Provider>
  );
}
